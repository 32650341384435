<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Etes-vous alors à votre propre compte (auto-emploi)? "
                v-model="editableItem.estEnAutoEmploi"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 mb-4" v-if="editableItem.estEnAutoEmploi === false">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Etes-vous recruté (e) par une autre entreprise ou travaillez-vous quelqu’un d’autre? "
                v-model="editableItem.estRecruteParAutreEntreprise"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div
            class="col-sm-12 mb-4"
            v-if="editableItem.estRecruteParAutreEntreprise === false"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Au cours des (7 derniers jours), avez-vous travaillé ne serait-ce qu'une heure contre rémunération (en espèce ou en nature)? "
                v-model="editableItem.hasTravaille7DerniersJours"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>

          <div v-if="editableItem.hasTravaille7DerniersJours === false" class="col-sm-12">
            <h5>
              Avez-vous réalisé l'une des activités suivantes, à domicile ou à
              l'extérieur, pour aider la famille contre rémunération (en espèce ou en
              nature)?
            </h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="activiteRealiseSemaineDerniere"
                  name="activiteRealiseSemaineDerniere"
                  aria-label="Sélection votre activité réalisée"
                  option-label="libelle"
                  option-value="id"
                  v-model="editableItem.activiteRealiseSemaineDerniere"
                  :options="serviceRendus"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="activiteRealiseSemaineDerniere">Service rendu</label>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";

export default {
  components: { CYesOrNoInput },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      datePerteTravail: this.value.datePerteTravail,
      travaillerSemainePasse: this.value.travaillerSemainePasse != null,
      hasEmploi: this.value.hasEmploi != null,
      hasFormationProfessionnelle: this.value.formationProfessionnelle != null,
      editableItem: this.value,
      niveauAlphabetions: [
        { value: "PAS_LIRE_PAS_ECRIRE", label: "Je ne sais pas lire et écrire" },
        { value: "LIRE_PAS_ECRIRE", label: "Je peux lire mais pas écrire" },
        { value: "LIRE_ET_ECRIRE", label: "Je sais lire et écrire" },
      ],
      plageSalaires: [
        { value: "MOINS_DE_40000", label: "Moins de 52 000 FCFA" },
        { value: "ENTRE_40000_ET_70000", label: "[52 000, 70 000 [" },
        { value: "PLUS_DE_70000", label: "70 000 FCFA ou plus" },
      ],
      raisonEmplois: [
        { value: "PERTE_DU_PRECEDENT", label: "Perte de l'emploi précédent" },
        {
          value: "NOUVELLE_INSERTION",
          label: "Nouvelle insertion sur le marché du travail (n'a jamais travaillé)",
        },
        {
          value: "TRAVAILLER_PLUS",
          label: "Souhaite travailler plus.",
        },
      ],
      typeEmplois: [
        { value: "SALARIE", label: "Salarié" },
        { value: "AUTO_EMPLOI", label: "Auto-emploi" },
        { value: "INDIFFERENT", label: "Indifférent" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
      serviceRendus: "serviceRendu/serviceRendus",
      sourceInfos: "sourceInfo/sourceInfos",
      formationProfessionnelles: "formationProfessionnelle/formationProfessionnelles",
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  watch: {},
  created() {
    this.fetchServiceRendus();
  },
  methods: {
    ...mapActions({
      fetchServiceRendus: "serviceRendu/fetchServiceRendus",
      fetchSourceInfos: "sourceInfo/fetchSourceInfos",
      fetchFormationProfessionnelles:
        "formationProfessionnelle/fetchFormationProfessionnelles",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
