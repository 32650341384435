<template>
  <PDialog
    :header="'Modification d\'enquête poste stage'"
    :height="550"
    :modal="true"
    :visible.sync="display"
    :contentStyle="{ overflow: '' }"
    :containerStyle="{ width: '70vw' }"
  >
    <div class="card border-none bg-light">
      <div class="card-">
        <!-- <questionnaire-poste-stage :demandeurId="demandeurId" /> -->
        <main>
          <div :class="'container-fluid'">
            <div class="mx-aut col-sm-12 py-5">
              <div class="bg-white py-4 px-4 rounded-lg shadow-md">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="text- mb-4">
                      <h4 class="text-primary">SITUATION D’EMPLOI APRES LE STAGE</h4>
                      <h6 class="text-muted">
                        Remplissage de la fiche poste stage de {{ demandeur.nom }}
                        {{ demandeur.prenom }}
                      </h6>
                    </div>
                  </div>
                </div>
                <p-progress-bar
                  v-if="loading"
                  style="height: 0.33em"
                  mode="indeterminate"
                />
                <p-divider />
                <div
                  v-if="loading"
                  class="d-flex justify-content-center pb-4"
                  style="min-height: 10vh"
                >
                  <div
                    class="spinner-border text-primary"
                    style="width: 5rem; height: 5rem"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div class="row" v-if="!loading">
                  <div class="col-sm-12">
                    <NiveauInstruction
                      v-if="demandeur.id != null"
                      v-model="fiche"
                      :readOnly="readOnly"
                      :ref="`ficheForm`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <template #footer>
      <PButton
        label="Annuler"
        icon="pi pi-times"
        @click="display = false"
        class="p-button-text"
      />
      <PButton label="Valider" icon="pi pi-check" @click="validateThenSubmit" />
    </template>
  </PDialog>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";
import NiveauInstruction from "../../../components/espace/demandeur/fiche-apres-stage/steps/NiveauInstruction.vue";
import { authMixin } from "../../../mixins/auth-mixin";

export default {
  props: {
    demandeurId: {
      type: [Number, String],
      required: true,
    },
    fichePosteProgrammeId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    NiveauInstruction,
  },
  mixins: [authMixin],
  data() {
    return {
      currentStep: this.initialStep,
      fiche: {},
      loading: false,
      display: false,
      demandeur: {},
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    ...mapActions({
      createOrUpdateFichePosteProgramme:
        "fichePosteProgramme/createOrUpdateFichePosteProgramme",
      fetchDossier: "demandeur/fetchD   ossier",
    }),
    loadData() {
      this.$emit("ready");

      if (this.item == null || (this.item && this.item.id == null)) {
        this.loading = true;
        this.fetchDossier({
          demandeurId: this.demandeurId,
        })
          .then((data) => {
            const { estInstruitEnquete, niveauInstruction, niveauInstructionEnquete } = {
              ...data,
              ...data.azoli,
            };
            this.fiche = {
              estInstruitEnquete,
              niveauInstruction,
              niveauInstructionEnquete,
            };
            this.demandeur = data;
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      } else {
        this.fiche = {
          ...this.item,
        };
        this.demandeur = this.item.beneficiaire;
        this.loading = false;
      }
    },
    async validateThenSubmit() {
      if (!(await this.$refs.ficheForm.isCompleted())) {
        this.$toast.error("Le formulaire semble contenir des champs non renseignés.", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment valider cette enquête?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.save();
        },
      });
    },
    show() {
      if (this.demandeur.id == null) {
        this.loadData();
      }
      this.display = true;
    },
    close() {
      this.display = false;
    },
    save() {
      this.createOrUpdateFichePosteProgramme({
        ...this.fiche,
        beneficiaireId: this.demandeurId,
        participationPlacementId: this.participationPlacementId,
      }).then((data) => {
        this.$emit("save", data);
        this.display = false;
        this.$toast.success("Modification enrégistrée avec succès!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
    }
    .vue-form-wizard .wizard-icon-circle{
    } */

/* .main-wizard {
      min-height: 60vh;
    } */
</style>
