<template>
  <div>
    <PDialog
      :header="`Annulation de placement - ${item.beneficiaire}`"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '40vw' }"
    >
      <div class="card mt border-0">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="card mt border-0">
            <form ref="form" method="post" @submit.prevent="handleSubmit()">
              <template>
                <div class="row">
                  <div class="col-sm-12 field mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <textarea
                          type="text"
                          rows="10"
                          style="height: 120px"
                          id="observation"
                          class="form-control"
                          name="observation"
                          v-model="editableItem.raisonAnnulation"
                        />
                        <label for="raison_annulation"
                          >Pourquoi vous avez annulez ce placement ?</label
                        >
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </template>
            </form>
          </div>
        </ValidationObserver>
      </div>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: { ...this.item },
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  created() {},
  computed: {
    ...mapGetters({}),
    options() {
      return this.structures.map((item) => ({
        value: item,
        text: item.libelle,
      }));
    },
  },
  methods: {
    ...mapActions({}),
    resetContent() {},

    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        statutPlacement: "ANNULE",
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
