/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box">
      <PlacementStatistic
        ref="placementStatistic"
        :extraQueryArg="extraQueryArg"
        class="mt-3 mb-2"
      />
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par '"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-3 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="statutPlacement"
                    :options="statutPlacementOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Statut du placement'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <div class="field col-12 col-md-3">
                <label for="basic">Date de début</label>
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3">
                <label for="basic">Date de fin</label>
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                  v-if="option.visible"
                  :key="index"
                >
                  <label :for="option.labelField">{{ option.label }}</label>

                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :emitObject="false"
                    :displayMode="option.displayMode"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des placements</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <PlacementEditor
            ref="stageFromationEditor"
            :item="activePlacement"
            :title="'Mise en stage/formation'"
            @save="savePlacement"
          />
          <!-- <a @click.prevent="addPlacement" href="" class="style_btn btn btn-warning">Ajouter un stage/formation</a> -->
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-white p-3 border">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              selectable
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(id)="data"> PP000{{ data.item.id }} </template>

              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>
              <template #cell(statutPlacement)="{ item: plc }">
                <PTag
                  class="text-uppercase badge badge-primary"
                  :severity="getStatutPlacementColor(plc.statutPlacement)"
                  v-if="plc.statutPlacement"
                  >{{ plc.statutPlacement }}
                </PTag>
              </template>
              <template #cell(createdDate)="{ item: prog }">
                <span class="text-uppercase">{{
                  $moment(prog.createdDate).format("DD-MM-YYYY HH:mm")
                }}</span>
              </template>
              <template #cell(creePar)="{ item: prog }">
                <span class="text-uppercase" v-if="prog.creePar"
                  >{{ prog.creePar.nom }} {{ prog.creePar.prenom }}</span
                >
              </template>

              <template #row-details="row">
                <PTabView ref="tabview1" :activeIndex.sync="activeTab">
                  <PTabPanel header="Bénéficiaires">
                    <ParticipationPlacementList
                      v-if="activeTab == 0"
                      :placement="row.item"
                      :extraQueryArg="`placement.id=${row.item.id}&placement.offreId=${row.item.offreId}`"
                    />
                  </PTabPanel>
                  <PTabPanel header="Point de présence">
                    <PointPresenceList
                      v-if="activeTab == 1"
                      :listTitleSuffix="row.item.entreprise.raisonSocial"
                      :extraQueryArg="`placementId=${row.item.id}`"
                      :placementId="row.item.id"
                    />
                  </PTabPanel>
                  <!-- <PTabPanel header="Présence au poste">
                    <PresencePosteList
                      v-if="activeTab == 2"
                      :extraQueryArg="`pointPresence.placementId=${row.item.id}`"
                    />
                  </PTabPanel> -->
                  <PTabPanel header="Prise de service">
                    <PriseServiceList
                      v-if="activeTab == 2"
                      :extraQueryArg="`participationPlacement.placementId=${row.item.id}`"
                    /> </PTabPanel
                  >f
                  <PTabPanel header="Cessation de service">
                    <ArretServiceList
                      v-if="activeTab == 3"
                      :extraQueryArg="`participationPlacement.placementId=${row.item.id}`"
                    />
                  </PTabPanel>
                  <PTabPanel header="Evaluation professionnelle">
                    <EvaluationProfessionnelleList
                      v-if="activeTab == 4"
                      :placementId="row.item.id"
                      :extraQueryArg="`participationPlacement.placementId=${row.item.id}`"
                    />
                  </PTabPanel>
                  <PTabPanel header="Evaluation stagiaire">
                    <AppreciationStageList
                      v-if="activeTab == 5"
                      :placementId="row.item.id"
                      :extraQueryArg="`participationPlacement.placementId=${row.item.id}`"
                    />
                  </PTabPanel>
                </PTabView>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-sm py-1 btn-outline-primary me-1"
                    v-tooltip.left="'Vue détaillée'"
                    @click="mxToggleDetails(row, tableData)"
                  >
                    <i class="bi bi-journal-check"></i>
                  </button>
                  <button
                    class="btn btn-sm py-1 btn-outline-danger me-1"
                    v-tooltip.left="'Clôturer/Archiver le placement'"
                    v-if="row.item.statutPlacement != 'ARCHIVE'"
                    @click.prevent="showArchiverPlacementEditorDialog(row.item)"
                  >
                    <i class="bi bi-archive"></i>
                  </button>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import PlacementEditor from "../../../components/espace/common/placement/editor/PlacementEditor.vue";
import ParticipationPlacementList from "./ParticipationPlacementList.vue";
import PresencePosteList from "../entreprise/suivi/PresencePosteList.vue";
import PointPresenceList from "../entreprise/suivi/PointPresenceList.vue";
import PriseServiceList from "../entreprise/suivi/PriseServiceList.vue";
import ArretServiceList from "../entreprise/suivi/ArretServiceList.vue";
import EvaluationProfessionnelleList from "../entreprise/formation/EvaluationProfessionnelleList.vue";
import AppreciationStageList from "../entreprise/formation/AppreciationStageList.vue";
// import SuiviPlacement from './ParticipationPlacementList.vue'
// import * as XLSX from 'xlsx'
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";
import PlacementStatistic from "../../../components/statistic/placement/PlacementStatistic.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    CAutoComplete,
    PlacementEditor,
    ParticipationPlacementList,
    EvaluationProfessionnelleList,
    AppreciationStageList,
    PresencePosteList,
    PointPresenceList,
    PriseServiceList,
    ArretServiceList,
    AsyncMultiSelect,
    PlacementStatistic,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      activePlacement: null,
      activePriseDeService: {},
      activeCessationService: {},
      activePresencePoste: {},
      dateFin: null,
      activeTab: 0,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: "EN_TRAITEMENT", label: "EN TRAITEMENT" },
        { value: "EN_COURS", label: "EN COURE" },
        { value: "ARCHIVE", label: "ARCHIVE" },
      ],
      statutPlacement: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des placements",
                subtitle: "Liste des placements",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "placements/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des placements.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des placements",
                subtitle: "Liste des placements",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "placements/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des placements.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["intituleOffre"],
      searchFieldsOptions: [
        {
          label: "Nom entreprise",
          value: "entreprise.raisonSocial",
          visible: true,
        },
        {
          label: "Titre de l'offre",
          value: "intituleOffre",
          visible: true,
        },
        {
          label: "IFU entreprise",
          value: "entreprise.ifu",
          visible: true,
        },
        {
          label: "Référence offre",
          value: "offre.reference",
          visible: true,
        },
        {
          label: "Référence Placement",
          value: "reference",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "entreprise.id",
          value: [],
          label: "Dénomination Entreprise",
          dropdown: true,
          labelField: "raisonSocial",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "entreprises",
          visible: true,
        },
        {
          column: "entreprise.id",
          value: [],
          label: "Ifu Entreprise",
          dropdown: true,
          labelField: "ifu",
          displayMode: "classic",
          valueKey: "ifu",
          queryUrl: "entreprises",
          visible: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "reference", label: "Ref" },
        { key: "entreprise.raisonSocial", label: "Entreprise" },
        //{ key: "entreprise", label: "Entreprise" },
        { key: "entreprise.nomDirigeant", label: "Dirigeant" },
        { key: "entreprise.ifu", label: "IFU" },
        { key: "offre.reference", label: "OffreRef" },
        { key: "miseEnRelationCount", label: "MER" },
        { key: "placesCount", label: "PLACES" },
        { key: "createdDate", label: "Date" },
        { key: "statutPlacement", label: "Statut" },
        "actions",
      ];
    },
    emptyPlacement: {
      observation: null,
      description: null,
      activiteProspection: null,
      natureContrat: null,
      entreprise: null,
      beneficiaire: null,
      metiers: null,
      dateDebut: null,
      dateFin: null,
      typePlacement: null,
      satisfactionDemandeur: null,
      satisfactionEmployeur: null,
      statutPlacement: null,
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      createOrUpdatePlacement: "placement/createOrUpdatePlacement",
      archiverPlacement: "placement/archiverPlacement",
    }),
    async showArchiverPlacementEditorDialog(placement) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment clôturer/archiver le  placement de ${placement.entreprise.raisonSocial}/${placement.intituleOffre}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.archiverPlacement(placement)
            .then((data) => {
              this.$toast.success("Placement clôturé avec succès!", {
                position: "top-right",
                duration: 8000,
              });
              this.queryStatistics();
            })
            .catch((err) => err);
        },
        reject: () => {},
      });
    },
    getStatutPlacementColor(statutPlacement) {
      let bg = "warning";

      if (statutPlacement == "EN_TRAITEMENT") {
        bg = "primary";
      } else if (statutPlacement == "EN_COURS") {
        bg = "info";
      } else if (statutPlacement == "ARCHIVE") {
        bg = "danger";
      }
      return bg;
    },
    addPlacement() {
      this.activePlacement = {
        ...this.emptyPlacement,
      };
      this.$refs.stageFromationEditor.show();
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "placement",
        search: this.search,
        fetcherMethod: "placement/fetchPlacements",
        dataGetter: "placement/placements",
        paginationGetter: "placement/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("statutPlacement", this.statutPlacement);

          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.conseiller.de.edit" });
    },
    savePlacement(item) {
      console.log("Placement: ", item);
      this.createOrUpdatePlacement({
        ...item,
        // beneficiaire: item.beneficiaires[0]
      });
    },
  },
};
</script>

<style></style>
