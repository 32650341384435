<template>
  <div class="row mt-3">
    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="showPopup($event, '_candidatures_count_popup_')"
        class="d-flex shadow align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-danger': activeBlock == 'NOUVEAU' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-danger style_level_candidature d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-danger"><i class="bi-briefcase"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3" :class="{ active: activeBlock == 'NOUVEAU' }">
          <h6 class="my-0 text-uppercase">CANDIDATURES</h6>
          <small class="text-muted">Total des candidatures</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="stat.totalCount" :options="options" />
          </h2>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="showPopup($event, '_candidatures_count_popup_')"
        class="d-flex shadow align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-danger': activeBlock == 'NOUVEAU' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-danger style_level_candidature d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-danger"><i class="bi-briefcase"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3" :class="{ active: activeBlock == 'NOUVEAU' }">
          <h6 class="my-0 text-uppercase">EVALUEE</h6>
          <small class="text-muted">Total des évaluées</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="stat.evalueCount" :options="options" />
          </h2>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock('TERMINE')"
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-primary': activeBlock == 'TERMINE' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-primary style_level_candidature d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-primary"><i class="bi-patch-check"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0 text-uppercase">RECOMMANDE</h6>
          <small class="text-muted">Total des RECOM</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="stat.recommandeeCount" :options="options" />
          </h2>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock('VALIDE')"
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
        :class="{ 'active border-success': activeBlock == 'VALIDE' }"
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-success style_level_candidature d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-success"><i class="bi-bookmark"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3" v-tooltip.bottom="'DE à autonomie inconnue'">
          <h6 v-tooltip.bottom="'DE à autonomie inconnue'" class="my-0 text-uppercase">
            MER
          </h6>
          <small class="text-muted">Total des MER</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp
              :delay="delay"
              :endVal="stat.miseEnRelationCount"
              :options="options"
            />
          </h2>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-2">
      <div
        @click.prevent="selectBlock(null)"
        ripple
        class="d-flex align-items-center bg-block rounded p-3 border p-ripple cursor-pointer"
        v-ripple
      >
        <div class="flex-shrink-0">
          <div
            class="border border-2 border-secondary style_level_candidature d-flex flex-column justify-content-center align-items-center"
          >
            <h3 class="my-0">
              <a href="#" class="text-secondary"><i class="bi-broadcast"></i></a>
            </h3>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6 class="my-0">PLACES</h6>
          <small class="text-muted">Total des PLACES</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="stat.placesCount" :options="options" />
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ICountUp from "vue-countup-v2";
import ConfirmPopup from "primevue/confirmpopup";

export default {
  props: {
    dateDeb: {
      type: [String, Date],
    },
    dateFin: {
      type: [String, Date],
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    ICountUp,
    ConfirmPopup,
  },
  data() {
    return {
      delay: 5000,
      candidatureStatusOptions: [
        "EN_ATTENTE",
        "VALIDE",
        "PUBLIE",
        "APPROUVE",
        "DEPUBLIE",
        "CLOTURE",
      ],
      candidatureTypeOptions: ["EXTERNE", "INTERNE"],
      candidatureNiveauServiceOptions: ["SERVICE_DELEGUE", "SERVICE_SEMI_DELEGUE"],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      activeBlock: null,
      stat: {
        evalueCount: 0,
        recommandeeCount: 0,
        spontanneeCount: 0,
        nonEvalueCount: 0,
        totalCount: 0,
        miseEnRelationCount: 0,
        placesCount: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dateDeb() {
      this.loadData();
    },
    dateFin() {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      fetchCandidatureCount: "statistic/fetchCandidatureCount",
    }),
    getCamelCaseName(name, separator = "_") {
      let camelCaseName = "";
      name.split("_").forEach((el, i) => {
        if (i == 0) {
          camelCaseName += el.toLowerCase();
        } else {
          camelCaseName += el[0].toUpperCase() + el.toLowerCase().slice(1);
        }
      });
      return camelCaseName;
    },
    selectBlock(status) {
      this.activeBlock = this.activeBlock != status ? status : null;
      this.$emit("selectionChange", this.activeBlock);
    },
    loadData() {
      this.fetchCandidatureCount({
        dateDeb: this.dateDeb,
        dateFin: this.dateFin,
        extraQueryArg: this.extraQueryArg,
      }).then((data) => {
        this.stat = data;
      });
    },
  },
};
</script>

<style scoped>
.bg-block {
  background: #ebe2a3;
  background: white;
  /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 4%) !important; */
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 4%) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%) !important;
}

.shadow:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 6%) !important;
}

.candidature-stat-value {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>
