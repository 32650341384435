<template>
  <div :id="id" :class="containerClass">
    <div class="list-group bg-white" v-if="orientation == 'vertical'">
      <a
        v-for="(item, index) of steps"
        :key="index"
        :href="item.to"
        :class="{ active: activeStepIndex == index }"
        @click="onItemClick($event, item)"
        class="list-group-item list-group-item-action cursor-pointer"
      >
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1 fw- text-capitilize">
            {{ label(item) }}
          </h6>
          <span
            class="badge rounded-pill"
            :class="{
              'bg-light text-primary': activeStepIndex == index,
              'bg-white text-secondary': index > activeStepIndex,
              'bg-primary text-white': index < activeStepIndex,
            }"
            >{{ index + 1 }}/{{ steps.length }}</span
          >
        </div>
        <p class="mb-1">{{ subtitle(item) }}</p>
      </a>
    </div>
    <!-- <PListBox v-model="selectedStep" :options="steps" optionLabel="label">
      <template #option="slotProps">
        <div class="w-100">
          <i
            class="text-5xl pi pi-check-circle text- mr-3"
            :class="{ ' text-green-light': slotProps.option.completed }"
            style="font-size: 1.6rem"
          ></i>
          <h6 class="text-h5">{{ slotProps.option.label }}</h6>
          <span>{{ slotProps.option.subtitle }}</span>
        </div>
      </template>
    </PListBox> -->
    <ul role="tablist text-wrap" v-if="orientation == 'horizontal'">
      <template v-for="(item, index) of steps">
        <li
          v-if="visible(item, index)"
          :class="getItemClass(item, index)"
          :key="index"
          :style="item.style"
          role="tab"
          :aria-selected="isActive(item, index)"
          :aria-expanded="isActive(item, index)"
        >
          <template v-if="!$slots.item">
            <router-link
              class="w-100"
              :to="item.to"
              v-if="!isItemDisabled(item, index)"
              custom
              v-slot="{ navigate, href, isActive, isExactActive }"
            >
              <a
                :href="href"
                :class="linkClass({ isActive, isExactActive })"
                @click="onItemClick($event, item, navigate)"
                role="presentation"
              >
                <div
                  class="card border w-100"
                  :class="{ 'border-primary': index == activeStepIndex }"
                >
                  <div class="d-flex card-body align-items-center px-4 border-1">
                    <i
                      class="text-5xl pi pi-check-circle text- mr-3"
                      :class="{ ' text-green-light': item.completed }"
                      style="font-size: 1.6rem"
                    ></i>
                    <div class="ms-4">
                      <span
                        class="p-steps-title text-900 font-medium text-xl mb-1 line-height-1 text-truncate"
                        >{{ label(item) }}</span
                      >
                      <span class="p-steps-subtitle">{{ subtitle(item) }}</span>
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <span v-else class="w-100" :class="linkClass()" role="presentation">
              <div class="card w-100">
                <div class="d-flex card-body align-items-center px-4 border-1">
                  <i class="text-5xl pi pi-check-circle text-900 mr-3"></i>
                  <div class="ms-4">
                    <span
                      class="p-steps-title text-900 font-medium text-xl mb-1 line-height-1"
                      >{{ label(item) }}</span
                    >
                    <span class="p-steps-subtitle">{{ subtitle(item) }}</span>
                  </div>
                </div>
              </div>
            </span>
          </template>
          <component v-else :is="$slots.item" :item="item"></component>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { UniqueComponentId } from "primevue/utils";
export default {
  name: "Steps",
  props: {
    id: {
      type: String,
      default: UniqueComponentId(),
    },
    steps: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    activeStepIndex: {
      type: Number,
      default: 0,
    },
    exact: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "classic", //classic,tab,
    },
    orientation: {
      type: String,
      default: "horizontal", //horizontal,vertical,
    },
  },
  data() {
    return {
      selectedStep: null,
    };
  },
  methods: {
    getStepIndexByName(stepName) {
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].name == stepName) return i;
      }
      return -1;
    },
    onItemClick(event, item, navigate) {
      if (this.disabled(item) || this.readonly) {
        event.preventDefault();
        return;
      }
      if (item.command) {
        item.command({
          originalEvent: event,
          item: item,
        });
      }
      if (item.to && navigate) {
        navigate(event);
      }
    },
    isActive(item, index) {
      // return item.to ? this.$router.resolve(item.to).path === this.$route.path : false;
      return index <= this.activeStepIndex;
    },
    getItemClass(item, index) {
      return [
        "p-steps-item",
        item.class,
        {
          "p-highlight p-steps-current": this.isActive(item, index),
          "p-disabled": this.isItemDisabled(item, index),
        },
      ];
    },
    linkClass(routerProps) {
      return [
        "p-menuitem-link",
        {
          "router-link-active": routerProps && routerProps.isActive,
          "router-link-active-exact":
            this.exact && routerProps && routerProps.isExactActive,
        },
      ];
    },
    isItemDisabled(item, index) {
      return this.disabled(item, index) || (this.readonly && !this.isActive(item, index));
    },
    visible(item) {
      return typeof item.visible === "function" ? item.visible() : item.visible !== false;
    },
    disabled(item) {
      return typeof item.disabled === "function" ? item.disabled() : item.disabled;
    },
    label(item) {
      return typeof item.label === "function" ? item.label() : item.label;
    },
    subtitle(item) {
      return typeof item.subtitle === "function" ? item.subtitle() : item.subtitle;
    },
  },
  computed: {
    containerClass() {
      return ["p-steps p-component", { "p-readonly": this.readonly }];
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
  },
};
</script>

<style>
.p-steps {
  position: relative;
}
.p-steps ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
}
.p-steps-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
}
.p-steps-item .p-menuitem-link {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
}
.p-steps.p-steps-readonly .p-steps-item {
  cursor: auto;
}
.p-steps-item.p-steps-current .p-menuitem-link {
  cursor: default;
}
.p-steps-title {
  white-space: nowrap;
}
.p-steps-subtitle {
  font-style: normal;
  text-transform: none;
}
.p-steps-number {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-steps-title {
  display: block;
}
.text-green-light {
  color: #22c55e;
}
</style>
