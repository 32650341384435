<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Avez-vous pu joindre le bénéficiaire? "
                v-model="editableItem.beneficiaireJoignable"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
          <div
            class="col-sm-12 field mt-3"
            v-if="editableItem.beneficiaireJoignable === false"
          >
            <h5 for="observation">Observation/Commentaire</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <textarea
                  type="text"
                  rows="10"
                  style="height: 120px"
                  id="observation"
                  class="form-control"
                  name="observation"
                  v-model="editableItem.observation"
                />
                <label for="observation">Observation/Commentaire</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="editableItem.beneficiaireJoignable" class="row">
          <div class="col-sm-12">
            <h5>Quel est votre dernier niveau d'étude?</h5>
            <!-- <div class="text-muted">
              Choix à l'inscription
              <span class="badge bg-danger text-bold h6">{{
                editableItem.niveauInstruction
                  ? editableItem.niveauInstruction.description
                  : "Aucun"
              }}</span>
            </div> -->
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <!-- <select :value="editableItem.niveauInstructionEnquete"  @input="handleInput" class="form-select" id="niveauInstructionEnquete" name="niveauInstructionEnquete" aria-label="Sélection votre niveau d'étude">
                <option :value="n.id" v-for="n in niveauInstructionEnquetes" :key="n.id">{{ n.description }}</option>
              </select> -->
                <c-select
                  id="niveauInstructionEnquete"
                  name="niveauInstructionEnquete"
                  option-label="description"
                  option-value="id"
                  v-model="editableItem.niveauInstructionEnquete"
                  :options="allInstructions"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="niveauInstructionEnquete">Niveau d'étude</label>
              </div>
            </ValidationProvider>
          </div>

          <!-- <div class="col-sm-12">
            <h5>De quel programme avez-vous bénéficié?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.typeProgrammeBeneficie"
                  @input="handleInput"
                  class="form-select"
                  id="typeProgrammeBeneficie"
                  name="typeProgrammeBeneficie"
                  aria-label="Sélection du type de programme bénéficié"
                >
                  <option
                    :value="n.value"
                    v-for="n in typeProgrammeBeneficies"
                    :key="n.id"
                  >
                    {{ n.label }}
                  </option>
                </select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="typeProgrammeBeneficie">Type de programme bénéficié</label>
              </div>
            </ValidationProvider>
          </div> -->

          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <h5>
                En quel mois et en quelle année avez-vous terminé votre stage ou démarré
                votre micro-projet?
              </h5>
              <label for="dateFinStageDebutMicroProjet"
                >Date de fin de stage ou de début du micro-projet
              </label>
              <div class="form-floating mb-4">
                <p-calendar
                  id="dateFinStageDebutMicroProjet"
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  view="month"
                  dateFormat="MM yy"
                  v-model="editableItem.dateFinStageDebutMicroProjet"
                  name="dateFinStageDebutMicroProjet"
                ></p-calendar>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <QuestionnaireProgrammeMicroProjet
            v-if="editableItem.typeProgrammeBeneficie == 'EMPLOI_INDEPENDANT'"
            v-model="editableItem"
          />
          <QuestionnaireProgrammeSalarie
            v-if="editableItem.typeProgrammeBeneficie == 'EMPLOI_SALARIE'"
            v-model="editableItem"
          />
          <template v-if="hasEmploi">
            <h5 class="fw-bold py-0 mt-4">
              Pour ceux qui douze mois après sont recrutés après leur stage ou qui sont en
              auto-emploi.
            </h5>
            <PDivider />
            <QuestionnaireProgrammeEvolutionRevenue v-model="editableItem" />
          </template>

          <template
            v-if="
              editableItem.activiteRealiseSemaineDerniere != null ||
              editableItem.heureConsacreAEmploiPrincipal != null
            "
          >
            <div class="col-sm-12 field mt-3">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <textarea
                    type="text"
                    rows="10"
                    style="height: 120px"
                    id="observation"
                    class="form-control"
                    name="observation"
                    v-model="editableItem.observation"
                  />
                  <label for="observation">Observation/Commentaire</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </template>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import CSelect from "@/components/CSelect.vue";
import MetierSelect from "../../../../../components/common/MetierSelect.vue";
import QuestionnaireProgrammeMicroProjet from "./QuestionnaireProgrammeMicroProjet.vue";
import QuestionnaireProgrammeSalarie from "./QuestionnaireProgrammeSalarie.vue";
import QuestionnaireProgrammeEvolutionRevenue from "./QuestionnaireProgrammeEvolutionRevenue.vue";

export default {
  components: {
    CYesOrNoInput,
    CSelect,
    MetierSelect /* YesOrNoBadge */,
    QuestionnaireProgrammeMicroProjet,
    QuestionnaireProgrammeSalarie,
    QuestionnaireProgrammeEvolutionRevenue,
  },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
      typeProgrammeBeneficies: [
        { label: "EMPLOI_SALARIE", value: "EMPLOI_SALARIE" },
        { label: "EMPLOI_INDEPENDANT", value: "EMPLOI_INDEPENDANT" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
    }),
    allInstructions() {
      return [{ id: null, nom: "Aucune", description: "Aucune" }, ...this.instructions];
    },
    hasEmploi() {
      return (
        this.editableItem.estEnAutoEmploi ||
        this.editableItem.estRecruteParAutreEntreprise ||
        this.editableItem.estRecruteParEntrepriseStage ||
        this.editableItem.hasTravaille7DerniersJours ||
        (this.editableItem.activiteRealiseSemaineDerniere != null &&
          this.editableItem.activiteRealiseSemaineDerniere.id <= 9)
      );
    },
  },
  watch: {},
  created() {
    this.fetchInstructions();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
    }),
    handleInput(e) {
      if (e.target.name === "niveauInstructionEnquete") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        if (
          this.editableItem.niveauInstructionEnquete &&
          this.editableItem.niveauInstructionEnquete.id == null
        ) {
          this.editableItem.niveauInstructionEnquete = null;
        }
      }
      return valid;
    },
  },
};
</script>

<style scoped></style>
