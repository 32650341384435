<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '30vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <div class="row grid-md">
                <div class="col-sm-12 field">
                  <div class="form-floating mb-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <h6 class="mb-4" for="libelle">
                        Vous devez lier le fichier scanné de présence au poste de
                        <span class="text-uppercase text-primary">
                          {{
                            $moment(editableItem.dateMoisPointPresence).format("MMM YYYY")
                          }}</span
                        >
                        (Le fichier PDF doit porter la signature de l'entreprise).
                      </h6>
                      <div>
                        <FilePondUploader
                          ref="fileUploader"
                          class="col-12 md:col-12"
                          v-model="editableItem.fichePresenceUrl"
                          @save="fileSaved"
                          :autoResize="true"
                          rows="8"
                        />
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Soumettre" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "../../../common/AsyncSearchInput.vue";
import FilePondUploader from "@/components/uploader/FilePondUploader.vue";

export default {
  components: {
    AsyncSearchInput,
    FilePondUploader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    hidePlacementField: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: {},
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {},
  computed: {},
  methods: {
    ...mapActions({}),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    fileSaved(files) {
      this.editableItem = {
        ...this.editableItem,
        fichePresenceUrl: this.$refs.fileUploader.getFilesUrls(),
      };
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
