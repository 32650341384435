<template>
  <div>
    <PDialog
      :header="'Générateur de convention entreprise'"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '40vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <h5>Plan pour la compétence {{ item.competenceMetier.libelle }}.</h5>
              <div class="row">
                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="number"
                        v-uppercase
                        id="duree"
                        class="form-control"
                        name="duree"
                        v-model="editableItem.duree"
                      />
                      <label for="duree">Durée en jours</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                    <!-- <small class="text-help"></small> -->
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="nomEmployeTraiteur"
                        class="form-control"
                        name="nomEmployeTraiteur"
                        v-model="editableItem.encadreur"
                      />
                      <label for="nomEmployeTraiteur">Encadreur</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <!-- <PButton
          label="Appliquer à toutes les compétences"
          icon="pi pi-check"
          @click="save"
          autofocus
        /> -->
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: {},
      search: "",
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
    // 'editableItem.typeContrat': {
    //   handler(val){
    //   }
    // }
  },
  created() {
    this.fetchTypeContrats();
  },
  computed: {
    ...mapGetters({
      typeContrats: "typeContrat/typeContrats",
    }),
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchTypeContrats: "typeContrat/fetchTypeContrats",
    }),
    resetContent() {},
    show() {
      // this.resetContent()
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
