<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <h5>
              Combien d'heures avez-vous consacrées à votre emploi principal au cours de
              la semaine dernière (ou habituellement) par semaine ?
            </h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  type="number"
                  v-model="editableItem.heureConsacreAEmploiPrincipal"
                  class="form-control"
                  id="heureConsacreAEmploiPrincipal"
                  name="heureConsacreAEmploiPrincipal"
                />
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="heureConsacreAEmploiPrincipal"
                  >Nombre d'heure de travail</label
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <h5>Quelle est votre rémunération mensuelle ?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  v-model="editableItem.plageSalaireActuel"
                  id="plageSalaireActuel"
                  name="plageSalaireActuel"
                  class="form-select"
                >
                  <option v-for="n in plageSalaires" :key="n.value" :value="n.value">
                    {{ n.label }}
                  </option>
                </select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="plageSalaireActuel">Plage de sailaire</label>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 mb-4">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Vos revenus ont-ils augmenté maintenant par rapport à ce que vous gagniez avant de bénéficier du projet?"
                v-model="editableItem.revenusOntAugmentes"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";

export default {
  components: { CYesOrNoInput },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      datePerteTravail: this.value.datePerteTravail,
      travaillerSemainePasse: this.value.travaillerSemainePasse != null,
      hasEmploi: this.value.hasEmploi != null,
      editableItem: this.value,
      plageSalaires: [
        { value: "MOINS_DE_40000", label: "Moins de 52 000 FCFA" },
        { value: "ENTRE_40000_ET_70000", label: "[52 000, 70 000 [" },
        { value: "PLUS_DE_70000", label: "70 000 FCFA ou plus" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      serviceRendus: "serviceRendu/serviceRendus",
      sourceInfos: "sourceInfo/sourceInfos",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear());
      return today;
    },
  },
  watch: {},
  created() {
    this.fetchServiceRendus();
  },
  methods: {
    ...mapActions({
      fetchServiceRendus: "serviceRendu/fetchServiceRendus",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
