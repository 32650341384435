<template>
  <div class="container-">
    <div class="row">
      <div class="col-md-12 mb-4">
        <AppreciationStageEditorDialog
          ref="editorDialog"
          :item="appreciationStage"
          @save="saveEvaluation"
        />
        <template v-if="loading">
          <div class="card shadow-0 border-0 px-3">
            <div class="field col-12 md:col-6 mt-2" v-for="i in 3" :key="i">
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2" height="2rem"></PSkeleton>
            </div>
          </div>
        </template>
        <div
          v-else
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                BENEFICIARE
                <slot name="title">
                  <div>
                    <span
                      ><b-btn
                        class="me-2"
                        size="xs"
                        v-if="appreciationStage != null && appreciationStage.id != null"
                        @click.prevent="telechargerAppreciationStage(appreciationStage)"
                        variant="outline-success"
                        ><i class="bi bi-printer"></i></b-btn
                    ></span>
                    <span
                      ><b-btn size="xs" @click.prevent="editerDossier" variant="success"
                        ><i class="bi bi-pencil"></i></b-btn
                    ></span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ pp.beneficiaireNom || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Prénom :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ pp.beneficiairePrenom || "-" }}
                    </div>
                  </div>

                  <hr />
                  <!-- <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">IFU :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ pp.ifu || "-" }}
                    </div>
                  </div>
                  <hr /> -->
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Phone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ pp.beneficiarePhone || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Appréciation de l'entreprise par le statigiaire
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 text-">
                    <table class="table table-">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Critères</th>
                          <th scope="col">Niveau de satisfaction</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(appStag, index) in eval.appreciationStagiaires"
                          :key="index"
                        >
                          <td>
                            <span>
                              {{
                                (appStag.critereAppreciation &&
                                  appStag.critereAppreciation.libelle) ||
                                "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                (appStag.niveauSatisfaction &&
                                  appStag.niveauSatisfaction.libelle) ||
                                "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Perspectives statigiaire
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 text-">
                    <table class="table table-">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Perspective</th>
                          <th scope="col">Décision</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            perspectiveStagiaire, index
                          ) in eval.perspectiveStagiaires"
                          :key="index"
                        >
                          <td>
                            <span>
                              {{
                                (perspectiveStagiaire.typePerspective &&
                                  perspectiveStagiaire.typePerspective.libelle) ||
                                "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                (perspectiveStagiaire.perspective &&
                                  perspectiveStagiaire.perspective.libelle) ||
                                "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";
import AppreciationStageEditorDialog from "@/components/espace/entreprise/editor/AppreciationStageEditorDialog.vue";

export default {
  props: {
    appreciationStageId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    YesOrNoBadge,
    AppreciationStageEditorDialog,
  },
  data() {
    return {
      appreciationStage: {},
      loading: true,
    };
  },
  created() {},
  watch: {
    appreciationStageId: {
      handler() {
        if (this.appreciationStageId) {
          this.loading = true;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    eval() {
      return this.appreciationStage;
    },
    pp() {
      return this.appreciationStage.id != null
        ? { ...this.appreciationStage.participationPlacement }
        : {};
    },
  },
  methods: {
    ...mapActions({
      fetchAppreciationStage: "appreciationStage/fetchAppreciationStage",
      updateAppreciationStage: "appreciationStage/updateAppreciationStage",
      telechargerAppreciationStage: "appreciationStage/telechargerAppreciationStage",
    }),
    loadData() {
      this.fetchAppreciationStage({
        appreciationStageId: this.appreciationStageId,
      }).then((data) => {
        this.loading = false;
        this.appreciationStage = { ...data };
      });
    },
    saveEvaluation(evaluation) {
      this.updateAppreciationStage(evaluation).then(() => {
        console.log(evaluation);
        this.loadData();
      });
    },
    editerDossier() {
      // this.$emit("edit");
      this.$refs.editorDialog.show();
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>
