/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <PlanCompetenceDialog
      v-if="activePlanCompetence != null"
      ref="planCompetenceDialog"
      :item="activePlanCompetence"
      :hidePlacementField="entrepriseId != null"
      :title="'Ajout de présence au poste'"
      @save="saveEvaluationProfessionnelle"
    />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-3 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="status"
                    :options="statusOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Statut'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des fiches d'évaluation professionnelle</h4>
        </div>
        <div class="btn-group">
          <a
            @click.prevent="genererEvaluationProfessionnelleEntreprise"
            href=""
            class="ms-2 style_btn btn btn-warning"
          >
            {{
              !mxLoading && tableData.length == 0
                ? "Générer les fiches d'évaluation professionnelle"
                : "Actualiser les fiches d'évaluation professionnelle"
            }}
          </a>
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                </template>
                <template #cell(tauxDeRemplissage)="{ item: plan }">
                  {{ plan.tauxDeRemplissage }}%
                </template>
                <template #row-details="row">
                  <PTabView :activeIndex.sync="activeTab">
                    <PTabPanel header="Fiche d'évaluation professionnelle">
                      <FicheEvaluationProfessionnelle
                        :evaluationProfessionnelleId="row.item.id"
                        :evaluationProfessionnelle="row.item"
                        v-if="activeTab == 0"
                        :extraQueryArg="`evaluationProfessionnelleId=${row.item.id}`"
                      />
                    </PTabPanel>
                  </PTabView>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir détails'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>

                    <button
                      class="btn btn-outline-success rounded-0 btn-sm me-1"
                      v-tooltip.top="'Evaluer'"
                      @click.stop="evaluerLeBeneficiaire(row.item)"
                    >
                      <i class="bi bi-check"></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                  <button
                    class="btn btn-primary"
                    @click="genererEvaluationProfessionnelleEntreprise"
                  >
                    Générer les fiches d'évaluation professionnelle
                  </button>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";

import PlanCompetenceDialog from "../../../../components/espace/entreprise/editor/PlanCompetenceDialog.vue";
import FicheEvaluationProfessionnelle from "./FicheEvaluationProfessionnelle";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    placementId: {
      type: Number,
      default: null,
    },
  },
  components: {
    PlanCompetenceDialog,
    FicheEvaluationProfessionnelle,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activePlanCompetence: {},
      evaluationProfessionnelle: null,
      dateDeb: null,
      activeTab: 0,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "NON_DEMARRE", label: "NON_DEMARRE" },
        { value: "EN_COURS", label: "EN_COURS" },
        { value: "CLOTURE", label: "CLOTURE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      status: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "evaluationProfessionnelles/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "evaluationProfessionnelles/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["intituleOffre"],
      searchFieldsOptions: [
        {
          label: "Référence placement",
          value: "referencePlacement",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Titre offre",
          value: "intituleOffre",
          visible: this.$can("filter.telephone", "Azoli"),
        },
      ],
      filterOptions: [],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    activeTab() {},
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "referencePlacement", label: "Ref" },
        { key: "participationPlacement.beneficiaireNom", label: "Nom" },
        { key: "participationPlacement.beneficiairePrenom", label: "Prénom" },
        { key: "status", label: "Statut" },
        { key: "tauxDeRemplissage", label: "Complétion" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ];
    },

    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateEvaluationProfessionnelle:
        "evaluationProfessionnelle/createOrUpdateEvaluationProfessionnelle",
      cloturerEvaluationProfessionnelle:
        "evaluationProfessionnelle/cloturerEvaluationProfessionnelle",
      genererEvaluationProfessionnelles:
        "evaluationProfessionnelle/genererEvaluationProfessionnelles",
      validerEvaluationProfessionnelle:
        "evaluationProfessionnelle/validerEvaluationProfessionnelle",
    }),
    editerPlanCompetence(planCompetence) {
      this.activePlanCompetence = { ...planCompetence };
      if (this.activePlanCompetence != null) {
        this.activePlanCompetence.entreprise = { id: this.entrepriseId };
      }
      this.$refs.planCompetenceDialog.show();
    },
    genererEvaluationProfessionnelleEntreprise() {
      this.genererEvaluationProfessionnelles({
        placement: {
          id: this.placementId,
        },
      }).then(() => {
        this.queryStatistics();
      });
    },
    showPlanCompetenceDialog() {
      this.activePlanCompetence = {};
      if (this.entrepriseId != null) {
        this.activePlanCompetence.entreprise = { id: this.entrepriseId };
      }
      this.$refs.planCompetenceDialog.show();
    },
    saveEvaluationProfessionnelle(evaluationProfessionnelle) {
      this.createOrUpdateEvaluationProfessionnelle(evaluationProfessionnelle)
        .then((data) => {
          this.$toast.success("Présence au poste sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    getStatusColor(status) {
      let color = "";
      if (status == "EN_COURS") {
        color = "primary";
      } else if (status == "VALIDE") {
        color = "success";
      } else if (status == "CLOTURE") {
        color = "warning";
      } else if (status == "RECALE") {
        color = "danger";
      } else if (status == "PROPOSE") {
        color = "info";
      } else if (status == "NON_DEMARRE") {
        color = "secondary";
      }
      return color;
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "evaluationProfessionnelle",
        search: this.search,
        fetcherMethod: "evaluationProfessionnelle/fetchEvaluationProfessionnelles",
        dataGetter: "evaluationProfessionnelle/evaluationProfessionnelles",
        paginationGetter: "evaluationProfessionnelle/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("status", this.status);

          let params = `${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
