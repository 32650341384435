<template>
  <div>
    <!-- <multiselect v-model="selected" id="ajax" label="libelle" track-by="id" placeholder="Type to search" open-direction="bottom" :options="items" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="20" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind"> -->
    <multiselect
      v-model.lazy="selected"
      id="ajax"
      v-bind="$attrs"
      open-direction="bottom"
      :options="items"
      :searchable="true"
      :placeholder="placeholder"
      :label="optionLabel"
      track-by="id"
      class=""
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="true"
      :options-limit="300"
      :limit="20"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :optionHeight="45"
      :hide-selected="true"
      @search-change="asyncFind"
      v-on="{
        ...$listeners,
      }"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <PTag severity="pri" class="custom__tag mt-1 me-2" v-if="option">
          <span>{{ option[optionLabel] }}</span>
          <PButton
            class="custom__remove p-button-sm p-0 px-0 ms-2"
            style="padding: 0px"
            @click="remove(option)"
            icon="pi pi-times"
          />
        </PTag>
      </template>
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="selected"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div>
      </template>
      <template>
        <span slot="noResult">Oops! Aucun élément trouvé.</span>
      </template>
      <template>
        <span slot="noOptions">Taper pour rechercher</span>
      </template>

      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc py-"
          ><span class="option__title">{{ props.option[optionLabel] }}</span></span
        >
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <div class="option__title" v-b-popover.hover.top="props.option[optionLabel]">
            {{ props.option[optionLabel] }}
          </div>
          <!-- <span class="option__small fs-italic mt-1">{{ props.option.famille.libelle }}</span>
          <PDivider class="py-0 my-0" /> -->
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { paginatorMixin } from "../../mixins/paginator-mixin";
import { get } from "../../api/api";

export default {
  props: {
    value: {
      type: [String, Object, Array],
    },
    queryUrl: {
      type: String,
      default: "naemas",
    },
    optionLabel: {
      type: String,
      default: "label",
    },
    placeholder: {
      type: String,
      default: "Rechercher ici",
    },
    searchOptions: {
      type: Array,
      default: () => ["libelle"],
    },
  },
  components: {
    Multiselect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      items: [],
      isLoading: false,
      // initialized: false,
      suggestions: [],
    };
  },
  created() {},
  watch: {
    selected() {
      if (this.suggestions.length != 0) {
        this.$emit("input", this.selected);
      }
    },
    value: {
      handler(val) {
        if (val != null && this.suggestions.length == 0) {
          if (this.value instanceof Array) {
            this.selected = [...this.value];
          } else {
            this.selected = [this.value];
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    // disabled () {
    //   if(this.option.enabled == null) return false
    //   if(this.option.enabled instanceof Function){
    //     return !this.option.enabled()
    //   }
    //   return !this.option.enabled
    // }
  },
  methods: {
    limitText(count) {
      return `et  ${count} autres éléments`;
    },
    asyncFind(query) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        let queryArg = "";

        this.searchOptions.forEach(
          (opt) => (queryArg += opt + "=" + query.toUpperCase() + "&size=20")
        );
        // console.log(`${this.queryUrl}?${queryArg}`)
        get(`${this.queryUrl}?${queryArg}`).then(({ data }) => {
          this.items = data instanceof Array ? data : data.content;
          this.isLoading = false;
        });
      }, 1000);
    },
    clearAll() {
      this.selected = [];
    },
    loadData(event) {
      // if(this.this.option.url == undefined) return this.suggestions = []

      let url = `${this.option.url}?${this.option.key}=${event.query}`;
      if (this.option.urlBuilder != null) {
        url = this.option.urlBuilder();
      }

      get(url).then(({ data }) => {
        this.suggestions = data instanceof Array ? data : data.content;
      });
    },
  },
};
</script>

<style></style>
