<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-5 mb-4">
        <QuestionnairePosteStageDialog
          :demandeurId="demandeurId"
          :fichePosteProgrammeId="fichePosteProgrammeId"
          :item="fiche"
          v-if="!loading"
          ref="ficheEditor"
          @save="handleFicheCompleted"
        />
        <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
        <h5 class="text-primary">SITUATION D’EMPLOI POSTE PROGRAMME</h5>

        <template v-if="!loading">
          <div v-if="fiche != null && fiche.dateFinStageDebutMicroProjet == null">
            <p-divider />
            <h6>Ce placement n'a pas encore de fiche d'enquête poste stage.</h6>
            <PButton
              class="p-button p-button-warning"
              label="Démarrer une enquếte"
              @click.prevent.stop="demarrerEnquette"
            />
          </div>

          <div
            v-if="fiche != null && fiche.dateFinStageDebutMicroProjet != null"
            class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
            :height="550"
          >
            <div class="card mb-3 border-1">
              <div class="card-header bg-white">
                <h5 class="card-title d-flex align-items-center justify-content-between">
                  I. Identification bénéficiaire
                  <!-- <span
                    ><b-btn size="xs" @click.prevent="editSection(0)" variant="success"
                      ><i class="bi bi-pencil"></i></b-btn
                  ></span> -->
                </h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Nom</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">{{ demandeur.nom }}</div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Prénom(s)</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ demandeur.prenom }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Sexe</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ demandeur.sexe }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">
                      Date de naissance (JJ/MM/AAAA)(pour ceux qui sont nés vers, écrire
                      31/12/AAAA)
                    </h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ $moment(demandeur.dateNais).format("DD/MM/YYYY") }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Département</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ demandeur.departement.nom || "-" }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Commune</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ demandeur.commune.nom || "-" }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Arrondissement</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ demandeur.arrondissement.nom || "-" }}
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Village/Quartier de ville</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    {{ demandeur.ville.nom || "-" }}
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro NPI</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ demandeur.npi || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <span> {{ demandeur.phone || "-" }}</span>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>

            <div class="card mb-3 border">
              <div class="card-header bg-white">
                <h5 class="card-title d-flex align-items-center justify-content-between">
                  V. Situation emploi poste stage
                  <span
                    ><b-btn size="xs" @click.prevent="editSection(4)" variant="success"
                      ><i class="bi bi-pencil"></i></b-btn
                  ></span>
                </h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Quel est votre dernier niveau d'étude?</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <span
                      v-if="fiche.niveauInstructionEnquete != null"
                      class="badge bg-primary text-bold h6"
                    >
                      {{ fiche.niveauInstructionEnquete.description }}
                    </span>
                    <span v-else class="badge bg-danger text-bold h6">Aucun</span>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">De quel programme avez-vous bénéficié?</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <span
                      :class="{
                        ' bg-primary': fiche.typeProgrammeBeneficie == 'EMPLOI_SALARIE',
                        ' bg-info text-black':
                          fiche.typeProgrammeBeneficie == 'EMPLOI_INDEPENDANT',
                      }"
                      class="badge text-bold h6"
                    >
                      {{ fiche.typeProgrammeBeneficie }}
                    </span>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">
                      Depuis combien de mois avez-vous fini votre stage ou démarré votre
                      micro-projet?
                    </h6>
                  </div>
                  <div class="col-sm-7 text-secondary text-uppercase">
                    {{ $moment(fiche.dateFinStageDebutMicroProjet).format("MMMM YYYY") }}
                  </div>
                </div>

                <hr />
                <template v-if="fiche.typeProgrammeBeneficie == 'EMPLOI_SALARIE'">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        L'entreprise dans laquelle vous avez fait votre stage vous
                        a-t-elle recrutée à l'issue de votre stage?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.estRecruteParEntrepriseStage" />
                    </div>
                  </div>
                  <hr />

                  <div class="row" v-if="fiche.estRecruteParEntrepriseStage === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Etes-vous recruté (e) par une autre entreprise?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.estRecruteParAutreEntreprise" />
                    </div>
                  </div>
                  <hr v-if="fiche.estRecruteParEntrepriseStage === false" />

                  <div class="row" v-if="fiche.estRecruteParAutreEntreprise === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Etes-vous alors à votre propre compte (auto-emploi)?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.estEnAutoEmploi" />
                    </div>
                  </div>
                  <hr v-if="fiche.estRecruteParAutreEntreprise === false" />

                  <div class="row" v-if="fiche.estEnAutoEmploi === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Au cours des (7 derniers jours), avez-vous travaillé ne serait-ce
                        qu'une heure contre rémunération (en espèce ou en nature)?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.hasTravaille7DerniersJours" />
                    </div>
                  </div>
                  <hr v-if="fiche.estEnAutoEmploi === false" />

                  <div class="row" v-if="fiche.hasTravaille7DerniersJours === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Bien que vous ayez déclaré ne pas avoir travaillé la dernière
                        semaine, avez-vous réalisé l'une des activités suivantes, à
                        domicile ou à l'extérieur, pour aider la famille contre
                        rémunération (en espèce ou en nature)?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        fiche.activiteRealiseSemaineDerniere != null
                          ? fiche.activiteRealiseSemaineDerniere.libelle
                          : "-"
                      }}
                    </div>
                  </div>
                  <hr v-if="fiche.hasTravaille7DerniersJours === false" />
                </template>

                <template v-if="fiche.typeProgrammeBeneficie == 'EMPLOI_INDEPENDANT'">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Etes-vous alors à votre propre compte (auto-emploi)?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.estEnAutoEmploi" />
                    </div>
                  </div>
                  <hr />

                  <div class="row" v-if="fiche.estEnAutoEmploi === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Etes-vous recruté (e) par une autre entreprise ou travaillez-vous
                        quelqu’un d’autre?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.estRecruteParAutreEntreprise" />
                    </div>
                  </div>
                  <hr v-if="fiche.estEnAutoEmploi === false" />

                  <div class="row" v-if="fiche.estRecruteParAutreEntreprise === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Au cours des (7 derniers jours), avez-vous travaillé ne serait-ce
                        qu'une heure contre rémunération (en espèce ou en nature)?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="fiche.hasTravaille7DerniersJours" />
                    </div>
                  </div>
                  <hr v-if="fiche.estRecruteParAutreEntreprise === false" />

                  <div class="row" v-if="fiche.hasTravaille7DerniersJours === false">
                    <div class="col-sm-5">
                      <h6 class="mb-0">
                        Avez-vous réalisé l'une des activités suivantes, à domicile ou à
                        l'extérieur, pour aider la famille contre rémunération (en espèce
                        ou en nature)?
                      </h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        fiche.activiteRealiseSemaineDerniere != null
                          ? fiche.activiteRealiseSemaineDerniere.libelle
                          : "-"
                      }}
                      <!-- <yes-or-no-badge :value="fiche.activiteRealiseSemaineDerniere" /> -->
                    </div>
                  </div>
                  <hr v-if="fiche.hasTravaille7DerniersJours === false" />
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { API_URL } from "../../../config/api-config";
import QuestionnairePosteStageDialog from "./QuestionnairePosteStageDialog.vue";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";

export default {
  props: {
    demandeurId: {
      type: Number,
      required: true,
    },
    fichePosteProgrammeId: {
      type: Number,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    YesOrNoBadge,
    QuestionnairePosteStageDialog,
  },
  data() {
    return {
      demandeur: {},
      activeEditableStep: null,
      loading: false,
      editMode: false,
      fiche: null,
      API_URL,
    };
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    // if (this.demandeurId) {
    //   this.loading = true;
    //   this.fetchDossier({
    //     demandeurId: this.demandeurId,
    //   }).then(() => (this.loading = false));
    // }
    this.loadData();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.demandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    de() {
      return this.demandeur;
    },
  },
  methods: {
    ...mapActions({
      fetchFichePosteProgramme: "fichePosteProgramme/fetchFichePosteProgramme",
    }),
    loadData() {
      if (this.fichePosteProgrammeId != null) {
        this.loading = true;
        this.fetchFichePosteProgramme({
          id: this.fichePosteProgrammeId,
        }).then((data) => {
          this.fiche = data;
          this.demandeur = data.beneficiaire;
          this.loading = false;
        });
      }
    },
    handleFicheCompleted(fiche) {
      this.fiche.beneficiaire = this.demandeur;
      this.fiche = fiche;
    },
    demarrerEnquette() {
      this.$refs.ficheEditor.show();
    },
    updateDemandeurVariable(val) {
      this.demandeur = {
        ...val,
        ...val.azoli,
      };
    },
    editSection() {
      this.$refs.ficheEditor.show();
    },
    detailUpdated(detail) {
      this.demandeur = {
        ...detail,
        ...detail.azoli,
      };
      this.editMode = false;
    },
    fileChanged(e) {
      this.demandeur.urlPhoto = e.previewUrl;
    },
    downloadFiche() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(e) {
      console.log(e);
    },
    remplirFiche(demandeur) {
      this.$router.push({
        name: "espace.ce.azoli.edit",
        params: { demandeurId: demandeur.id },
      });
    },
    profileUpdate(e) {
      console.log(e);
      this.demandeur = {
        ...this.demandeur,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
  },
};
</script>

<style scoped>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
.p-dialog .p-dialog-content {
  padding: 0px;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>
