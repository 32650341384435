<template>
  <div>
    <PDialog :header="title" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'visible'}" :containerStyle="{ width: '40vw'}">
      <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
            <template>
              <div class="row">
                  <div class="col-sm-12 field mb-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div>Date de cessation de service</div>
                        <p-calendar
                          class="col-12 md:col-12"
                          :showIcon="true"
                          size="sm"
                          locale="fr"
                          name="dateDemarrage"
                          :manualInput="false"
                          v-model="editableItem.dateArret"
                          :showWeek="true"
                          />  
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select id="typeArret" name="typeArret" option-label="label" option-value="value" :emitObject="false" v-model="editableItem.typeArret" :options="typeArretContrats" class="form-select">
                        </c-select>
                        <label>Raison de cessation de service</label>
                      </div>
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>
                  
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="" v-slot="{ errors }">
                      <div class="mt-2">Observation/Commentaire</div>
                      <Textarea rows="6" class="form-control" v-model="editableItem.observation" />
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </template>
            </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>
  
  <script>
  import { paginatorMixin } from '@/mixins/paginator-mixin'
  import { mapActions, mapGetters } from 'vuex'
  import Echelon from '../../../common/echelon/Echelon.vue'
  import CSelect from '@/components/CSelect.vue'
  
  
export default {
    components: {
      Echelon,
      CSelect
    },
    props: {
      title: {
        type:  String,
        required: true
      },
      item: {
        type:  Object,
        default: () => ({ })
      }
    },
    mixins: [paginatorMixin],
    data () {
      return {
        display: false,
        editableItem: {},
      }
    },
    watch: {
      item: {
        handler(){
          this.editableItem = { ...this.item }
        },
        deep: true
      },
    },
    created () {
    },
    computed: {
      ...mapGetters({
        typeArretContrats: 'setting/typeArretContrats'
      }),
    },
    methods: {
      ...mapActions({
      }),
      show () {
        this.display = true
      },
      hide () {
        this.display = false
      },
      cancel () {
        this.hide()
        this.$emit('canceled')
      },
      async isCompleted () {
        const valid = await this.$refs.observer.validate()
        return valid
      },
      async save(){
        if(!await this.isCompleted()) return;
        this.$emit('save', {
          ...this.editableItem
        })
        this.editableItem = {}
        this.hide()
      },
    }
    
  }
  </script>