<template>
<div>
  <!-- {{yesBadgeClass}} -->
  <span class="badge" :class="{ [yesBadgeClass]: value === true, [noBadgeClass]: value === false, [unknownBadgeClass]: value == null }"> {{ value === true? 'OUI' : value === false? 'NON' : '-' }}</span>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    yesBadgeClass: {
      type: String,
      default: 'bg-primary'
    },
    noBadgeClass: {
      type: String,
      default: 'bg-danger'
    },
    unknownBadgeClass: {
      type: String,
      default: 'text-secondary'
    },
  },
  data() {
    return {
      
    }
  },
}
</script>