<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      class="stepper-dialog"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{ width: '70vw' }"
    >
      <template #header>
        <div class="card px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              <div>
                {{ title }}
              </div>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <DStepper
            class="shadow-light"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
        </div>
      </template>
      <div class="bg-white shadow-light rounded-3 bg-r p-3">
        <ValidationObserver ref="observer0" v-slot="{ handleSubmit }">
          <form
            ref="form0"
            method="post"
            v-if="activeStep.name == 'EVALUATION_COMPORTEMENTALE'"
            @submit.prevent="handleSubmit()"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="row">
                <div
                  class="col-sm-12 field"
                  v-for="(evalCpmt, index) in editableItem.evaluationComportementales"
                  :key="index"
                >
                  <div class="mb-4">
                    <h6>{{ evalCpmt.competenceComportementale.libelle }}</h6>
                    <div
                      class="field-radiobutton"
                      v-for="(niveau, idx) in niveauCompetences"
                    >
                      <PRadioButton
                        class="mr-3"
                        :id="`niveauCompetence_${index}_${idx}`"
                        name="niveauCompetence"
                        v-model="evalCpmt.niveauCompetence"
                        :value="niveau"
                      />
                      <label class="ml" :for="`niveauCompetence_${index}_${idx}`">{{
                        niveau.libelle
                      }}</label>
                    </div>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </div>
              </div>
            </ValidationProvider>
          </form>
        </ValidationObserver>
        <ValidationObserver
          ref="observer1"
          v-if="activeStep.name == 'EVALUTATION_COMPETENCE'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form1" method="post" @submit.prevent="handleSubmit()">
            <div class="row">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="row">
                  <div
                    class="col-sm-12 field"
                    v-for="(evalCpc, index) in editableItem.evaluationCompetences"
                    :key="index"
                  >
                    <div class="mb-4">
                      <h6>{{ evalCpc.competenceMetier.libelle }}</h6>
                      <div
                        class="field-radiobutton"
                        v-for="(niveau, idx) in niveauCompetences"
                      >
                        <PRadioButton
                          class="mr-3"
                          :id="`niveauCompetenceMertier_${index}_${idx}`"
                          name="niveauCompetenceMertier_"
                          v-model="evalCpc.niveauCompetence"
                          :value="niveau"
                        />
                        <label
                          class="ml"
                          :for="`niveauCompetenceMertier_${index}_${idx}`"
                          >{{ niveau.libelle }}</label
                        >
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>
        <ValidationObserver
          ref="observer2"
          v-if="activeStep.name == 'PERSPECTIVE_ENTREPRISE'"
          v-slot="{ handleSubmit }"
        >
          <form ref="form2" method="post" @submit.prevent="handleSubmit()">
            <div class="row">
              <div
                class="col-sm-12 field"
                v-for="(perspectiveEntreprise, i) in editableItem.perspectiveEntreprises"
                :key="i"
              >
                <div class="mb-4">
                  <h6>{{ perspectiveEntreprise.typePerspective.libelle }}</h6>
                  <div
                    class="field-radiobutton"
                    v-for="(option, idx) in perspectiveOptions.filter((el) =>
                      [
                        perspectiveEntreprise.typePerspective.typeOption,
                        'AUTRES',
                      ].includes(el.typeOption)
                    )"
                    :key="idx"
                  >
                    <PRadioButton
                      class="mr-3"
                      :id="`perspective_${i}`"
                      :name="`perspective_${i}`"
                      v-model="perspectiveEntreprise.perspective"
                      :value="option"
                    />
                    <label class="pa-1" :for="`perspective_${i}`">{{
                      option.libelle
                    }}</label>
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <input
                    v-if="
                      perspectiveEntreprise.perspective != null &&
                      perspectiveEntreprise.perspective.typeOption == 'AUTRES'
                    "
                    type="TO"
                    name=""
                    v-model="perspectiveEntreprise.autrePerspectiveEntreprise"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text p-button-danger"
        />
        <PButton
          label="Précédent"
          icon="pi pi-chevron-left"
          :disabled="activeStepIndex <= 0"
          class="p-button-outlined"
          @click.prevent="goToPrevious"
        />
        <PButton
          label="Suivant"
          icon="pi  pi-chevron-right"
          v-if="activeStepIndex < steps.length - 1"
          @click.prevent="goToNext"
        />
        <PButton
          class="p-button p-button-primary"
          label="Valider"
          icon="pi pi-clock"
          v-if="activeStepIndex == steps.length - 1"
          @click="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";
import DStepper from "@/components/common/DStepper.vue";

export default {
  components: {
    AsyncSearchInput,
    DStepper,
  },
  props: {
    title: {
      type: String,
      default: "Evaluation professionnelle",
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      activeStepIndex: 0,
      activeTab: 0,
      beneficiaires: [],
      editableItem: {},
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {
    this.fetchNiveauCompetences();
    this.fetchPerspectiveOptions();
    this.fetchTypePerspectives();
  },
  computed: {
    ...mapGetters({
      niveauCompetences: "niveauCompetence/niveauCompetences",
      typePerspectives: "typePerspective/typePerspectives",
      perspectiveOptions: "perspectiveOption/perspectiveOptions",
    }),
    steps() {
      let steps = [
        {
          name: "EVALUATION_COMPORTEMENTALE",
          label: "EVALUATION COMPORTEMENTALE",
          subtitle: "Evaluation des compétences comportementales",
          to: "#npiValidation",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
        {
          name: "EVALUTATION_COMPETENCE",
          label: "EVALUATION DES COMPETENCES",
          subtitle: "Evaluation des compétences",
          to: "#verification-dossier",
          completed: this.activeStepIndex >= 1,
          command: () => {},
        },
        {
          name: "PERSPECTIVE_ENTREPRISE",
          label: "PERSPECTIVE ENTREPRISE",
          subtitle: "Perspective entreprise",
          to: "#verification-dossier",
          completed: this.activeStepIndex >= 2,
          command: () => {},
        },
      ];

      return steps;
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    tableFields() {
      let fields = [
        "#",
        "index",
        { key: "beneficiaireNom", label: "Nom" },
        { key: "beneficiairePrenom", label: "Prénom" },
        { key: "beneficiairePhone", label: "Téléphone" },
        // { key: "beneficiaire.commune.nom", label: "Commune" },
        { key: "metier.libelle", label: "Métier" },
      ];
      return fields;
    },
    entrepriseTableFields() {
      let fields = [
        "#",
        "index",
        { key: "ifu", label: "IFU" },
        { key: "raisonSocial", label: "Dénomination" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
        { key: "departement.nom", label: "Département" },
      ];
      return fields;
    },
  },
  methods: {
    ...mapActions({
      fetchNiveauCompetences: "niveauCompetence/fetchNiveauCompetences",
      fetchTypePerspectives: "typePerspective/fetchTypePerspectives",
      fetchPerspectiveOptions: "perspectiveOption/fetchPerspectiveOptions",
    }),

    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    async isCompleted(step) {
      const valid = await this.$refs["observer" + step].validate();
      return valid;
    },
    extraQueryArgsEntrepriseParamBuilder() {
      return ``;
    },
    extraQueryArgsParamBuilder() {
      return ``;
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("save", {
        ...this.editableItem,
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
